p.expansion-panel-description {
  margin: $spacing-base 0 $spacing-small 0 !important;
  width: 50%;
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded), 
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing),
.mat-expansion-panel {
  border-radius: 2px !important;
}

.expansion-panel-container {
  display: flex;
  flex-direction: column;
}

.expansion-panel-group:last-child {
    margin-right: 0 !important; 
}

.expansion-panel-group {
  height: fit-content;
  background-color: $white;
  padding: $spacing-base;
  margin-bottom: $spacing-large;
  border-radius: 2px; 
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
  margin-bottom: px2rem(1) !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded:hover {
  background-color: $primary-light !important;
}

.mat-expansion-panel-header {
  padding: px2rem(5) px2rem(15)!important;
  height: fit-content !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: fit-content !important;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  justify-content: space-between; 
}

.mat-expansion-panel-header-title h4 {
  margin-bottom:0 !important;
  color: $dark !important;
  font-size: $text-small !important;
  line-height: 21px !important; 
}

.mat-expansion-indicator {
  border-radius: 50%;
  background: $white;
  padding: 0px 6px;
}

.mat-expansion-indicator::after {
  color: $dark;
  transform:rotate(45deg) translate(-1px, -1px) !important;
  border-width: 0 1px 1px 0 !important;
}

.expansion-panel-statistic-block {
  margin: $spacing-xs 0  px2rem(6) 0;
  font-size: $text-small !important;
  line-height: px2rem(21)!important;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  column-gap: 10px;
}

.expansion-panel .mat-list-base .mat-list-item, 
.expansion-panel .mat-list-base .mat-list-option {
    height: auto;
}

.mat-list-base .mat-list-item .mat-list-item-content, 
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 !important;
}
