.form-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: px2rem(28);

  @include breakpoint(desktop-sm) {
    flex-direction: column;
    align-items: baseline;
  }

  &.data-comparison-form-container {
    @include breakpoint(desktop-lg) {
    flex-direction: column;
    align-items: baseline;
  }
  }
}

.form-element {
  display: flex;
  flex-direction: column;
  margin-right: $spacing-base;
}

.mat-input-element {
  color: $dark !important;
  margin-bottom: px2rem(3) !important;
}

.form-select .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 px2rem(20) 0 px2rem(15)!important;
}

.form-element .label {
  font-family: 'Suisse Medium', sans-serif;
  font-size: $text-xs;
  color: $dark;
  margin-bottom: $spacing-xxs;
}

.mat-select-arrow {
  $size: 9px;
  border-right: 2px solid $dark!important;
  border-bottom: 2px solid $dark!important;
  border-left: none!important;
  border-top: none!important;
  width: $size !important;
  height: $size !important;
  transform: rotate(45deg)!important;
  margin: 5px 0 0 0!important;
}

.form-element .mat-form-field,
.mat-option {
  color: $medium;
  font-size: $text-base !important;
  line-height: px2rem(20);
}

.mat-form-field-appearance-outline .mat-form-field-outline,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $medium !important; 
  height: $spacing-xl;
  top: 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: $error !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}

.mat-form-field-outline-start {
  border-radius: 2px 0 0 2px !important;
  min-width: 2px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 2px 2px 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  height: $spacing-xl;
  margin-top: 0 !important;
  padding: 0 px2rem(14) 0 px2rem(13)!important;
}

.form-element .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
  padding: 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: px2rem(3) 0 $spacing-xs 0!important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $dark !important;
}

.search-icon {
  margin-right: px2rem(13);
}
