$primary: #D0103A;
$primary-light: #F1B7C4;
$active: #7D0A23;
$hover: #A20023;
$white: #FFF;
$grey: #E2E2E2;
$medium: #888888;
$dark: #363A36;
$light: #C4C4C4;
$alert: #D0421B;
$success: #009241;
$green: #015151;
$yellow: #FAA21B;
$blue: #1B99D7;
$maroon: #8A1D41;
$turquoise: #0E9390;
$error: #f44336;
$box-shadow: #696969;
