 @mixin breakpoint($point) {
  @if $point == ipad {
    @media (max-width: 768px) { @content ; }
  }
  @else if $point == desktop-sm {
    @media (max-width: 992px) { @content ; }
  }
  @else if $point == desktop-lg {
    @media (max-width: 1200px) { @content ; }
  }
  @else if $point == desktop-xl {
    @media (max-width: 1320px) { @content ; }
  }
}
