$base-font-size: 16;
$large-font-size: $base-font-size * 1.2;

$spacing-xxs: 0.125rem;
$spacing-xs: 0.25rem;
$spacing-small: 0.5rem;
$spacing-base: 1rem;
$spacing-medium: 1.625rem;
$spacing-large: 2rem;
$spacing-xl: 3rem;

$font-fallback: Helvetica, Arial, sans-serif;
