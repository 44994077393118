$font-path: "/assets/fonts/";

@font-face {
  font-family: "Suisse Bold";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Suisse Bold"),
    url(#{$font-path}suisseintl-bold-webs.woff) format("woff"),
    url(#{$font-path}suisseintl-bold-webs.ttf) format("truetype"),
    url(#{$font-path}suisseintl-bold-webs.svg#webfont) format("svg"),
    url(#{$font-path}suisseintl-bold-webs.eot?#iefix)
      format("embedded-opentype");
}
@font-face {
  font-family: "Suisse Light";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Suisse Light"),
    url(#{$font-path}suisseintl-light-webs.woff) format("woff"),
    url(#{$font-path}suisseintl-light-webs.ttf) format("truetype"),
    url(#{$font-path}suisseintl-light-webs.svg#webfont) format("svg"),
    url(#{$font-path}suisseintl-light-webs.eot?#iefix)
      format("embedded-opentype");
}
@font-face {
  font-family: "Suisse Regular";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Suisse Regular"),
    url(#{$font-path}suisseintl-regular-webs.woff) format("woff"),
    url(#{$font-path}suisseintl-regular-webs.ttf) format("truetype"),
    url(#{$font-path}suisseintl-regular-webs.svg#webfont) format("svg"),
    url(#{$font-path}suisseintl-regular-webs.eot?#iefix)
      format("embedded-opentype");
}
@font-face {
  font-family: "Suisse Italic";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Suisse Italic"),
    url(#{$font-path}suisseintl-regularitalic-webs.woff) format("woff"),
    url(#{$font-path}suisseintl-regularitalic-webs.ttf) format("truetype"),
    url(#{$font-path}suisseintl-regularitalic-webs.svg#webfont) format("svg"),
    url(#{$font-path}suisseintl-regularitalic-webs.eot?#iefix)
      format("embedded-opentype");
}
@font-face {
  font-family: "Suisse Medium";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Suisse Medium"),
    url(#{$font-path}suisseintl-medium-webs.woff) format("woff"),
    url(#{$font-path}suisseintl-medium-webs.ttf) format("truetype"),
    url(#{$font-path}suisseintl-medium-webs.svg#webfont) format("svg"),
    url(#{$font-path}suisseintl-medium-webs.eot?#iefix)
      format("embedded-opentype");
}
@font-face {
  font-family: "Suisse Regular Italic";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Suisse Regular Italic"),
    url(#{$font-path}suisseintl-regularitalic-webs.woff) format("woff"),
    url(#{$font-path}suisseintl-regularitalic-webs.ttf) format("truetype"),
    url(#{$font-path}suisseintl-regularitalic-webs.svg#webfont) format("svg"),
    url(#{$font-path}suisseintl-regularitalic-webs.eot?#iefix)
      format("embedded-opentype");
}
