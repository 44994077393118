$text-xxs: 0.625rem; // 10
$text-xs: 0.75rem; // 12
$text-small: 0.875rem; // 14
$text-base: 1rem; // 16
$text-medium: 1.25rem; // 20
$text-large: 1.75rem; // 28

h1,
h2,
h3,
h4,
h5 {
  $header-font: "Suisse Medium", $font-fallback;
  font-family: $header-font !important;
}

.header-primary {
  font-size: $text-large !important;
  color: $primary;
  margin-bottom: px2rem(14) !important;
}

.header-secondary {
  font-size: $text-medium !important;
  color: $dark;
  margin-bottom: px2rem(6) !important;
}

.subheader-primary {
  font-size: $text-base !important;
  color: $primary;
  margin-bottom: px2rem(10) !important;
}

.subheader-secondary {
  font-size: $text-base !important;
  color: $dark;
  margin-bottom: px2rem(10) !important;
}

.header-tagline {
  $header-tagline-font: "Suisse Medium", $font-fallback;
  font-size: $text-small !important;
  line-height: px2rem(21) !important;
  margin-bottom: 0;
  font-family: $header-tagline-font;
}

.header-bg {
  background-color: $grey;
  width: px2rem(45);
  text-align: center;
  padding: px2rem(5) 0;
  border-radius: 2px;
}

.text-success {
  color: $success;
}

.text-alert {
  color: $alert;
}

.text-xxs,
.text-xxs p {
  font-size: $text-xxs;
  line-height: px2rem(15);
}

.text-xs,
.text-xs p {
  font-size: $text-xs;
  line-height: px2rem(16);
}

.text-base,
.text-base p {
  font-size: $text-base;
  line-height: px2rem(24);
}

.text-medium,
.text-medium p {
  font-size: $text-medium;
}

.text-description {
  color: $medium;
  font-size: $text-xxs;
  line-height: px2rem(15);
}
