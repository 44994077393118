button {
  background-color: transparent;
  border: none;
}

.btn-container {
  display: flex;
}

.btn {
  font-size: $text-base;
  border-radius: 2px;
  height: $spacing-xl;
  padding: 0 px2rem(20);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-sm {
  font-size: $text-xxs;
  padding: 0 px2rem(10);
  height: px2rem(25);
}

.btn-primary {
  background-color: $primary;
  color: $white;

  &:disabled {
    background: $medium;
  }

  &:not([disabled]) {
    &:hover {
      background: $hover;
    }
    &:active {
      background: $active;
    }
  }
}

.btn-secondary {
  border: 1px solid $medium;
  color: $dark;

  &:active {
    color: $active;
    border-color: $active;
  }

  &:hover {
    border: 1px solid $dark;
  }

  &.active {
    color: $active;
    border-color: $active;

    &:active {
      border-color: $medium;
      color: $dark;
    }
  }
}

.btn-transparent {
  &:hover {
    background: $grey;
  }
}

.btn-rounded {
  border: 1px solid $primary;
  color: $dark;
  border-radius: 28px;
}

.btn-active {
  background-color: $primary-light;
}
