table th.mat-header-cell:first-of-type,
table td.mat-cell:first-of-type,
table td.mat-footer-cell:first-of-type,
table th.mat-header-cell:last-of-type,
table td.mat-cell:last-of-type,
table td.mat-footer-cell:last-of-type {
  padding: 0 $spacing-small;
}

.mat-cell {
  font-size: $text-xxs !important;
  padding: 0 $spacing-small !important;
  color: $dark;
}

tr.mat-row,
tr.mat-header-row {
  height: auto !important;
}

.mat-table {
  margin: $spacing-small 0;
  box-shadow: none !important;
}

table.mat-table {
  border-spacing: 1px;
}

.mat-table-border {
  border-top: 1px solid $light;
}

.table-equal-spacing-3 .mat-cell {
  width: 33%;
}

.mat-table-equal-spacing-2 .mat-cell {
  width: 50;
}

.mat-header-cell {
  $mat-header-cell-font: "Suisse Medium", $font-fallback;
  color: $dark !important;
  font-size: $text-xxs !important;
  font-family: $mat-header-cell-font;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-style: unset !important;
  padding: 0 $spacing-small !important;
}

.mat-row:nth-child(odd) {
  background-color: $grey !important;
}

.table-overview {
  display: flex;
}

.table-overview-column {
  width: 50%;
}

.table-overview-header {
  margin: px2rem(7) px2rem(13) $spacing-xs px2rem(13) !important;
  font-size: $text-small !important;
}

.table-overview-border {
  border-top: 2px solid $light;
}

.table-overview-column:first-child {
  margin-right: px2rem(1);
}

.table-overview-row {
  background-color: $light;
  padding: px2rem(6) px2rem(13);
  font-size: $text-small;
}

.b-top {
  border-top: 1px solid white;
}

