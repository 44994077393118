html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Suisse Regular", Helvetica, Arial, sans-serif;
  color: $dark !important;
}

a {
  text-decoration: none;
  display: inline-block;
  margin: 0;
  color: unset;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.icon {
  color: $dark;
}

.icon-sm {
  $sm: 23px;
  width: $sm;
  height: $sm;
}

.icon-md {
  $md: 33px;
  width: $md;
  height: $md;
}

.icon-lg {
  $lg: 40px;
  width: $lg;
  height: $lg;
}

.open-toggle {
  position: absolute;
  z-index: 4;
  cursor: pointer;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0 !important;
}

.mat-divider {
  border-top-color: $grey !important;
  border-top-width: 2px !important;
}

.mat-divider:last-child {
  margin-bottom: px2rem(5);
}

.mat-divider.mat-divider-vertical {
  border-top-color: $grey !important; 
  border-top-width: 2px !important; 
  border-right-width: 2px !important; 
  margin: px2rem(-22) $spacing-base;
  height: px2rem(92);
}

.list-item {
  width: 100%;
  margin-top: px2rem(5);
}

.page-column-blocks {
  display: flex;
  flex-direction: column;
}

.page-block-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  flex-direction: row; 

  @include breakpoint(desktop-sm) {
    flex-direction: column;
  }
}

.page-block-container > * {
  margin-right: $spacing-large;
}

.page-block-container-3 > * {
  width: 33%;

  @include breakpoint(desktop-sm) {
    width: 100%;
  }
}

.page-block-container-4 > * {
  width: 25%;

   @include breakpoint(desktop-sm) {
    width: 100%;
  }
}

.scroll-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
}

.navbar-menu-options {
  display: flex;

  @include breakpoint(desktop-lg) {
    border-top: 1px solid $grey;
    width: 100%;
    padding-top: px2rem(25);
  }
}

.mat-card.card {
  margin: 0 px2rem(35) $spacing-large 0;
  padding: px2rem(40) px2rem(40) px2rem(24) px2rem(44);
  box-shadow: none !important;
  color: $dark !important;
}

hr {
  margin: $spacing-medium 0;
  width: 100%;
}

.search-validation-message {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center; 
  align-items: center;
}
